import BaseBean from "@/utils/BaseBean";

export interface IOaApplyListDataObj {
    utilInst:OaApplyListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class OaApplyListUtil extends BaseBean{
    public dataObj:IOaApplyListDataObj

    constructor(proxy:any,dataObj:IOaApplyListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.oaZxbData=await this.utils.OrderProviderApi.getAllOaZxb({});//查询所有中信保账户
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
}